var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addForm" },
    [
      _c(
        "BlockTitle",
        { attrs: { id: "title", title: _vm.title } },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("a-switch", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isFlag,
                    expression: "isFlag",
                  },
                ],
                attrs: { size: "small" },
                model: {
                  value: _vm.switchFlag,
                  callback: function ($$v) {
                    _vm.switchFlag = $$v
                  },
                  expression: "switchFlag",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "a-form-model",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.switchFlag,
              expression: "switchFlag",
            },
          ],
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "参保主体",
                prop: !_vm.disabledList.includes("company")
                  ? "insureCompanyId"
                  : "",
              },
            },
            [
              !_vm.disabledList.includes("company")
                ? _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择参保主体" },
                      on: { change: _vm.changeCompany },
                      model: {
                        value: _vm.form.insureCompanyId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "insureCompanyId", $$v)
                        },
                        expression: "form.insureCompanyId",
                      },
                    },
                    _vm._l(_vm.companyList, function (company) {
                      return _c(
                        "a-select-option",
                        {
                          key: company.companyId,
                          attrs: { value: company.companyId },
                        },
                        [_vm._v(" " + _vm._s(company.companyName) + " ")]
                      )
                    }),
                    1
                  )
                : _c("p", [_vm._v(_vm._s(_vm.form.companyName))]),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "参保方案",
                prop: !_vm.disabledList.includes("scheme") ? "schemeId" : "",
              },
            },
            [
              !_vm.disabledList.includes("scheme")
                ? _c(
                    "a-select",
                    {
                      attrs: {
                        disabled: _vm.disabledList.includes("programme"),
                        placeholder: "请选择参保方案",
                      },
                      model: {
                        value: _vm.form.schemeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "schemeId", $$v)
                        },
                        expression: "form.schemeId",
                      },
                    },
                    _vm._l(_vm.schemeList, function (scheme) {
                      return _c(
                        "a-select-option",
                        {
                          key: scheme.schemeId,
                          attrs: { value: scheme.schemeId },
                        },
                        [_vm._v(" " + _vm._s(scheme.schemeName) + " ")]
                      )
                    }),
                    1
                  )
                : _c("p", [_vm._v(_vm._s(_vm.form.schemeName))]),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "缴纳基数",
                prop: !_vm.disabledList.includes("paymentBase")
                  ? "paymentBaseType"
                  : "",
              },
            },
            [
              !_vm.disabledList.includes("paymentBase")
                ? [
                    _c(
                      "a-radio-group",
                      {
                        on: { change: _vm.changepayment },
                        model: {
                          value: _vm.form.paymentBaseType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "paymentBaseType", $$v)
                          },
                          expression: "form.paymentBaseType",
                        },
                      },
                      [
                        _c("a-radio", { attrs: { value: "1" } }, [
                          _vm._v(" 固定金额 "),
                        ]),
                        _c("a-radio", { attrs: { value: "2" } }, [
                          _vm._v(" 自定义金额 "),
                        ]),
                      ],
                      1
                    ),
                    _c("NumThousand", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.paymentBaseType === "1",
                          expression: "form.paymentBaseType === '1'",
                        },
                      ],
                      attrs: {
                        "v-model": _vm.form.fixedPaymentBase,
                        placeholder: "请输入基数",
                        "decimal-separator": true,
                        "int-val": _vm.form.fixedPaymentBase,
                        "is-thousands": true,
                        suffix: "元",
                        precision: "2",
                      },
                      on: { blur: _vm.inputChangeFun },
                    }),
                    _c(
                      "a-row",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.paymentBaseType === "2",
                            expression: "form.paymentBaseType === '2'",
                          },
                        ],
                        attrs: { gutter: 20 },
                      },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 14 } },
                          [
                            _c("DictionariesInput", {
                              staticClass: "dictionariesInput",
                              attrs: {
                                placeholder: "请选择自定义金额",
                                parameter: "custom_scale_type",
                              },
                              model: {
                                value: _vm.form.customScaleType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "customScaleType", $$v)
                                },
                                expression: "form.customScaleType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 10 } },
                          [
                            _c("CbNumber", {
                              attrs: {
                                "decimal-separator": false,
                                precision: "2",
                                max: 100,
                                suffix: "%",
                                placeholder: "请输入比例",
                              },
                              on: { change: _vm.valid },
                              model: {
                                value: _vm.form.customScale,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "customScale", $$v)
                                },
                                expression: "form.customScale",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _c("p", [_vm._v(_vm._s(_vm.form.fixedPaymentBase))]),
            ],
            2
          ),
          !_vm.stopInsure
            ? _c(
                "a-form-model-item",
                { attrs: { label: "缴纳起始月", prop: "startMonth" } },
                [
                  !_vm.disabledList.includes("month")
                    ? _c("a-month-picker", {
                        attrs: {
                          "disabled-date": _vm.disabledDate,
                          placeholder: "请选择月份",
                          "value-format": "YYYY-MM",
                        },
                        model: {
                          value: _vm.form.startMonth,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "startMonth", $$v)
                          },
                          expression: "form.startMonth",
                        },
                      })
                    : _c("p", [_vm._v(_vm._s(_vm.form.startMonth))]),
                ],
                1
              )
            : _vm._e(),
          _vm.stopInsure
            ? _c(
                "a-form-model-item",
                { attrs: { label: "缴纳停止月", prop: "endMonth" } },
                [
                  !_vm.disabledList.includes("month")
                    ? _c("a-month-picker", {
                        attrs: {
                          "disabled-date": _vm.disabledDate,
                          placeholder: "请选择月份",
                          "value-format": "YYYY-MM",
                        },
                        model: {
                          value: _vm.form.endMonth,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "endMonth", $$v)
                          },
                          expression: "form.endMonth",
                        },
                      })
                    : _c("p", [_vm._v(_vm._s(_vm.form.endMonth))]),
                ],
                1
              )
            : _vm._e(),
          _vm.stopInsure
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label:
                      _vm.insureClassify === "housing"
                        ? "变更原因"
                        : "减员原因",
                    prop: "decreasesReason",
                  },
                },
                [
                  !_vm.disabledList.includes("reason")
                    ? _c(
                        "a-select",
                        {
                          attrs: {
                            "get-popup-container": function (triggerNode) {
                              return triggerNode.parentNode
                            },
                          },
                          model: {
                            value: _vm.form.decreasesReason,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "decreasesReason", $$v)
                            },
                            expression: "form.decreasesReason",
                          },
                        },
                        _vm._l(_vm.decreases_reason, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.codeKey,
                              attrs: { value: item.codeKey },
                            },
                            [_vm._v(" " + _vm._s(item.content) + " ")]
                          )
                        }),
                        1
                      )
                    : _c("p", [_vm._v(_vm._s(_vm.form.decreasesReason))]),
                ],
                1
              )
            : _vm._e(),
          _vm.insureClassify === "housing" && !_vm.batch
            ? _c(
                "a-form-model-item",
                { attrs: { label: "公积金账号" } },
                [
                  _c("a-input", {
                    attrs: {
                      disabled: _vm.stopInsure,
                      placeholder: "以前在本地的话需要录入",
                    },
                    model: {
                      value: _vm.form.housingCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "housingCode", $$v)
                      },
                      expression: "form.housingCode",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }